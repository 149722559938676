// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.heroT2
{
    width: 100%;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 41px;
    color: #FFF;
    text-align: center;
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/HeroT2.css"],"names":[],"mappings":";AACA;;IAEI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,0BAA0B;IAC1B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["\n.heroT2\n{\n    width: 100%;\n    max-width: 750px;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 41px;\n    color: #FFF;\n    text-align: center;\n    font-family: IBM Plex Sans;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
