// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
    background-color: black;
    flex: 1 1;
    padding: 32px;
    /* margin-top: 50px; */
    color: white;
  }

  @media screen and (max-width: 1400px) {
    .main-content {
      padding-left: 20px;
      padding-right: 20px;
    }
    
  }
  
  
  @media screen and (max-width: 1200px) {
    .main-content {
      padding-right: 16px;
      padding-left: 16px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/layout/Content/Content.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE;MACE,kBAAkB;MAClB,mBAAmB;IACrB;;EAEF;;;EAGA;IACE;MACE,mBAAmB;MACnB,kBAAkB;IACpB;EACF","sourcesContent":[".main-content {\n    background-color: black;\n    flex: 1;\n    padding: 32px;\n    /* margin-top: 50px; */\n    color: white;\n  }\n\n  @media screen and (max-width: 1400px) {\n    .main-content {\n      padding-left: 20px;\n      padding-right: 20px;\n    }\n    \n  }\n  \n  \n  @media screen and (max-width: 1200px) {\n    .main-content {\n      padding-right: 16px;\n      padding-left: 16px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
