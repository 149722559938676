// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bigBoxblack {
  background: #000000;
}
.bigBox {
  text-align: center;
  padding: 40px 0;
  background: #a6ef6733;
  padding-bottom: 175px;
}
h1 {
  color: #a6ef67;
  font-family: IBM Plex Sans;

  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p {
  color: #ffffff;
  font-family: IBM Plex Sans;

  font-size: 20px;
  margin: 0;
}
i {
  color: #a6ef67;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.card {
  background: rgb(0, 0, 0);
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #a6ef67;
  display: inline-block;
  margin: 0 auto;
}

.sbox {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #a6ef6733;
  margin: 0 auto;
}

.referalC{
  color: #FFD700;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/PaymentSuccess.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,0BAA0B;;EAE1B,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,0BAA0B;;EAE1B,eAAe;EACf,SAAS;AACX;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,wBAAwB;EACxB,aAAa;EACb,kBAAkB;EAClB,6BAA6B;EAC7B,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,YAAY;EACZ,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".bigBoxblack {\n  background: #000000;\n}\n.bigBox {\n  text-align: center;\n  padding: 40px 0;\n  background: #a6ef6733;\n  padding-bottom: 175px;\n}\nh1 {\n  color: #a6ef67;\n  font-family: IBM Plex Sans;\n\n  font-weight: 900;\n  font-size: 40px;\n  margin-bottom: 10px;\n}\np {\n  color: #ffffff;\n  font-family: IBM Plex Sans;\n\n  font-size: 20px;\n  margin: 0;\n}\ni {\n  color: #a6ef67;\n  font-size: 100px;\n  line-height: 200px;\n  margin-left: -15px;\n}\n.card {\n  background: rgb(0, 0, 0);\n  padding: 60px;\n  border-radius: 4px;\n  box-shadow: 0 2px 3px #a6ef67;\n  display: inline-block;\n  margin: 0 auto;\n}\n\n.sbox {\n  border-radius: 200px;\n  height: 200px;\n  width: 200px;\n  background: #a6ef6733;\n  margin: 0 auto;\n}\n\n.referalC{\n  color: #FFD700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
