// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS for Videobox */
.videobox {
    margin-top: 43px;
    width: 100%;
    max-width: 400px; /* Set a maximum width for the video box */
    max-height: 225px; /* Set a maximum height for the video box */
    aspect-ratio: 16 / 9; /* Aspect ratio of 16:9 (width / height) */
    border-radius: 12px;
    background: #d9d9d9;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}


.playbutton{
  top: 50%;
  transform: translateY(-50%);
    margin-left:auto; 
    margin-right: auto ;
    position: relative;
    width: 80px;
    height:80px;
}

.circleplay{
    float: left;
    height: 80px;
    width: 80px;
    
}

.triangleplay{
    float: left;
    margin-top: 30%;
    margin-left: -65%;
    height: 32px;
    width: 32px;

}


  /* Media query for responsiveness */
  @media (max-width: 600px) {
    .videobox {
      max-width: 100%; /* Full width on smaller screens */
      max-height: none; /* Remove the maximum height restriction on smaller screens */
    }
  
    
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/Videobox.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB,EAAE,0CAA0C;IAC5D,iBAAiB,EAAE,2CAA2C;IAC9D,oBAAoB,EAAE,0CAA0C;IAChE,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;;AAGA;EACE,QAAQ;EACR,2BAA2B;IACzB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;;AAEf;;AAEA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,WAAW;;AAEf;;;EAGE,mCAAmC;EACnC;IACE;MACE,eAAe,EAAE,kCAAkC;MACnD,gBAAgB,EAAE,6DAA6D;IACjF;;;EAGF","sourcesContent":["/* CSS for Videobox */\n.videobox {\n    margin-top: 43px;\n    width: 100%;\n    max-width: 400px; /* Set a maximum width for the video box */\n    max-height: 225px; /* Set a maximum height for the video box */\n    aspect-ratio: 16 / 9; /* Aspect ratio of 16:9 (width / height) */\n    border-radius: 12px;\n    background: #d9d9d9;\n    position: relative;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n\n.playbutton{\n  top: 50%;\n  transform: translateY(-50%);\n    margin-left:auto; \n    margin-right: auto ;\n    position: relative;\n    width: 80px;\n    height:80px;\n}\n\n.circleplay{\n    float: left;\n    height: 80px;\n    width: 80px;\n    \n}\n\n.triangleplay{\n    float: left;\n    margin-top: 30%;\n    margin-left: -65%;\n    height: 32px;\n    width: 32px;\n\n}\n\n\n  /* Media query for responsiveness */\n  @media (max-width: 600px) {\n    .videobox {\n      max-width: 100%; /* Full width on smaller screens */\n      max-height: none; /* Remove the maximum height restriction on smaller screens */\n    }\n  \n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
