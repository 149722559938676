// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Accordion.css */

.accordion-container {
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .accordion-item {
    border-bottom: 0px solid #ccc;
  }
  
  .accordion-header {
    color: white;
    font-size: 18px;
    font-family: IBM Plex Sans;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #000000;
  }
  
  .accordion-content {
    padding: 10px;
    color: #98a2b3;
    font-size: 14px;
    font-family: IBM Plex Sans;

  }
  
  .accordion-icon {
    width: 10px;
    height: 10px;
    border-left: 3px solid #98A2B3;
    border-bottom: 3px solid #98A2B3;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: transform 0.3s ease;
  }
  
  .accordion-icon.expanded {
    transform: rotate(-225deg);
  }

  .accordion-title{
    margin-right: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/Accordian.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,0BAA0B;IAC1B,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,cAAc;IACd,eAAe;IACf,0BAA0B;;EAE5B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,gCAAgC;IAChC,kBAAkB;IAClB,yBAAyB;IACzB,+BAA+B;EACjC;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["/* Accordion.css */\n\n.accordion-container {\n    width: 100%;\n    max-width: 900px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  \n  .accordion-item {\n    border-bottom: 0px solid #ccc;\n  }\n  \n  .accordion-header {\n    color: white;\n    font-size: 18px;\n    font-family: IBM Plex Sans;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px;\n    cursor: pointer;\n    background-color: #000000;\n  }\n  \n  .accordion-content {\n    padding: 10px;\n    color: #98a2b3;\n    font-size: 14px;\n    font-family: IBM Plex Sans;\n\n  }\n  \n  .accordion-icon {\n    width: 10px;\n    height: 10px;\n    border-left: 3px solid #98A2B3;\n    border-bottom: 3px solid #98A2B3;\n    border-radius: 2px;\n    transform: rotate(-45deg);\n    transition: transform 0.3s ease;\n  }\n  \n  .accordion-icon.expanded {\n    transform: rotate(-225deg);\n  }\n\n  .accordion-title{\n    margin-right: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
