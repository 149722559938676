// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* grid c1 */
.grid-c1 {
  display: flex;
  flex-direction: column;
}
.grid-c1-content {
  background: linear-gradient(
      114.07deg,
      rgba(66, 59, 55, 0.6) 3.49%,
      rgba(66, 59, 55, 0) 34.7%
    ),
    linear-gradient(138.58deg, rgba(59, 43, 30, 0.37) 43.56%, #fea767 112.68%);
  flex: 1 1;
  border-radius: 10px;
  padding: 16px;
}
.grid-c1-content .lg-value {
  margin-top: 8px;
  margin-bottom: 16px;
  color: #c7c4c6;
}
.grid-c1-content .expiry-text {
  margin-bottom: 6px;
}
.grid-c1-content .card-wrapper {
  display: flex;
}
.grid-c1-content .card-pin-hidden {
  font-size: 32px;
  margin-right: 8px;
}
.grid-c1-content span {
  color: #8a8587;
}
.grid-c1 .card-logo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.grid-c1 .card-logo {
  display: flex;
}
.grid-c1 .logo-shape1,
.grid-c1 .logo-shape2 {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}
.grid-c1 .logo-shape1 {
  background-color: var(--clr-scarlet-v1);
  margin-right: -15px;
  z-index: 0;
}
.grid-c1 .logo-shape2 {
  background-color: var(--clr-yellow);
}

@media screen and (max-width: 768px) {
  .grid-c1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/components/Cards/Cards.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE;;;;;8EAK4E;EAC5E,SAAO;EACP,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;;EAEE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,uCAAuC;EACvC,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,mCAAmC;AACrC;;AAEA;EACE;IACE,oBAAoB;IACpB,kBAAkB;EACpB;AACF","sourcesContent":["/* grid c1 */\n.grid-c1 {\n  display: flex;\n  flex-direction: column;\n}\n.grid-c1-content {\n  background: linear-gradient(\n      114.07deg,\n      rgba(66, 59, 55, 0.6) 3.49%,\n      rgba(66, 59, 55, 0) 34.7%\n    ),\n    linear-gradient(138.58deg, rgba(59, 43, 30, 0.37) 43.56%, #fea767 112.68%);\n  flex: 1;\n  border-radius: 10px;\n  padding: 16px;\n}\n.grid-c1-content .lg-value {\n  margin-top: 8px;\n  margin-bottom: 16px;\n  color: #c7c4c6;\n}\n.grid-c1-content .expiry-text {\n  margin-bottom: 6px;\n}\n.grid-c1-content .card-wrapper {\n  display: flex;\n}\n.grid-c1-content .card-pin-hidden {\n  font-size: 32px;\n  margin-right: 8px;\n}\n.grid-c1-content span {\n  color: #8a8587;\n}\n.grid-c1 .card-logo-wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.grid-c1 .card-logo {\n  display: flex;\n}\n.grid-c1 .logo-shape1,\n.grid-c1 .logo-shape2 {\n  width: 36px;\n  height: 36px;\n  border-radius: 100%;\n}\n.grid-c1 .logo-shape1 {\n  background-color: var(--clr-scarlet-v1);\n  margin-right: -15px;\n  z-index: 0;\n}\n.grid-c1 .logo-shape2 {\n  background-color: var(--clr-yellow);\n}\n\n@media screen and (max-width: 768px) {\n  .grid-c1 {\n    grid-column-start: 1;\n    grid-column-end: 3;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
