// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assests/Images/about-background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.About_main__u9N7P{
    padding-top: 40px;
    height: 60vw;
    /* background-color: black; */
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
}

.About_heading__fgkC7{
    text-align: center;
    padding: 30px;
}

.About_content__aHwTY{
    padding: 20px 20vw;
}

@media screen and (max-width: 925px) {
    .About_main__u9N7P{
        height: 100vw;
    }
  }

@media screen and (max-width: 700px) {
    .About_main__u9N7P{
        height: 120vw;
    }
    
    .About_content__aHwTY p{
        font-size: 15px;
    }
  }

  @media screen and (max-width: 490px) {
    .About_main__u9N7P{
        height: 140vw;
    }
    
    .About_content__aHwTY p{
        font-size: 12px;
    }
  }

  @media screen and (max-width: 370px) {
    .About_main__u9N7P{
        height: 200vw;
    }
    
    .About_content__aHwTY p{
        font-size: 12px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/About/About.module.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,6BAA6B;IAC7B,yDAAkE;IAClE,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;IACjB;EACF;;AAEF;IACI;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;EACF;;EAEA;IACE;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;EACF;;EAEA;IACE;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;EACF","sourcesContent":["\n.main{\n    padding-top: 40px;\n    height: 60vw;\n    /* background-color: black; */\n    background-image: url(\"../../Assests/Images/about-background.jpg\");\n    background-size: cover;\n    background-repeat: no-repeat;\n}\n\n.heading{\n    text-align: center;\n    padding: 30px;\n}\n\n.content{\n    padding: 20px 20vw;\n}\n\n@media screen and (max-width: 925px) {\n    .main{\n        height: 100vw;\n    }\n  }\n\n@media screen and (max-width: 700px) {\n    .main{\n        height: 120vw;\n    }\n    \n    .content p{\n        font-size: 15px;\n    }\n  }\n\n  @media screen and (max-width: 490px) {\n    .main{\n        height: 140vw;\n    }\n    \n    .content p{\n        font-size: 12px;\n    }\n  }\n\n  @media screen and (max-width: 370px) {\n    .main{\n        height: 200vw;\n    }\n    \n    .content p{\n        font-size: 12px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `About_main__u9N7P`,
	"heading": `About_heading__fgkC7`,
	"content": `About_content__aHwTY`
};
export default ___CSS_LOADER_EXPORT___;
