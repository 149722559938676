import React from "react";
import "./Dashboard.module.css"
import Content from "./layout/Content/Content"
const DashBoard = () => {


    return (
      <>

      <Content/>

      
      
      </>
    )

}

export default DashBoard;