// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimeTable_top__TTrS3{
    padding-top: 60px;
}

.TimeTable_bg__zsb6D{
    color:white;
    background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/TimeTable/TimeTable.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":[".top{\n    padding-top: 60px;\n}\n\n.bg{\n    color:white;\n    background-color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": `TimeTable_top__TTrS3`,
	"bg": `TimeTable_bg__zsb6D`
};
export default ___CSS_LOADER_EXPORT___;
