// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heroT1
{
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 14px;
    letter-spacing: 1px; 
    text-align: center;   
}

.whiteT1{
    color: #FFF;
text-align: center;
font-family: IBM Plex Sans;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.greenT1{
    color: #A6EF67;
font-family: IBM Plex Sans;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: normal;

}
`, "",{"version":3,"sources":["webpack://./src/components/css/HeroT1.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf,kBAAkB;AAClB,0BAA0B;AAC1B,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB;;AAEA;IACI,cAAc;AAClB,0BAA0B;AAC1B,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;;AAEnB","sourcesContent":[".heroT1\n{\n    width: 100%;\n    max-width: 500px;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 14px;\n    letter-spacing: 1px; \n    text-align: center;   \n}\n\n.whiteT1{\n    color: #FFF;\ntext-align: center;\nfont-family: IBM Plex Sans;\nfont-size: 48px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\n}\n\n.greenT1{\n    color: #A6EF67;\nfont-family: IBM Plex Sans;\nfont-size: 48px;\nfont-style: normal;\nfont-weight: 600;\nline-height: normal;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
