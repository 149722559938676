// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* grid c6 */
.grid-c6 .grid-item .avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 100%;
}
.grid-c6 .grid-item-top-l {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.grid-c6 .grid-item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.grid-c6 .grid-item-top-r {
  font-weight: 600;
}
.grid-c6 .grid-item-badges {
  display: flex;
  flex-wrap: wrap;
}
.grid-c6 .grid-item-badge {
  color: #8a8587;
  background-color: var(--clr-jet);
  display: inline-block;
  border-radius: 16px;
  padding: 4px 6px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  margin-right: 6px;
}
.grid-c6 .grid-item-progress {
  background-color: var(--clr-primar-light);
  height: 10px;
  border-radius: 100vh;
  margin-top: 8px;
}
.grid-c6 .grid-item-fill {
  width: 60%;
  height: 10px;
  background-color: var(--clr-pumpkin);
  border-radius: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/components/Savings/Savings.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,cAAc;EACd,gCAAgC;EAChC,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,yCAAyC;EACzC,YAAY;EACZ,oBAAoB;EACpB,eAAe;AACjB;AACA;EACE,UAAU;EACV,YAAY;EACZ,oCAAoC;EACpC,oBAAoB;AACtB","sourcesContent":["/* grid c6 */\n.grid-c6 .grid-item .avatar {\n  width: 40px;\n  height: 40px;\n  overflow: hidden;\n  border-radius: 100%;\n}\n.grid-c6 .grid-item-top-l {\n  display: flex;\n  align-items: center;\n  column-gap: 10px;\n}\n.grid-c6 .grid-item-top {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n}\n.grid-c6 .grid-item-top-r {\n  font-weight: 600;\n}\n.grid-c6 .grid-item-badges {\n  display: flex;\n  flex-wrap: wrap;\n}\n.grid-c6 .grid-item-badge {\n  color: #8a8587;\n  background-color: var(--clr-jet);\n  display: inline-block;\n  border-radius: 16px;\n  padding: 4px 6px;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  font-size: 14px;\n  margin-right: 6px;\n}\n.grid-c6 .grid-item-progress {\n  background-color: var(--clr-primar-light);\n  height: 10px;\n  border-radius: 100vh;\n  margin-top: 8px;\n}\n.grid-c6 .grid-item-fill {\n  width: 60%;\n  height: 10px;\n  background-color: var(--clr-pumpkin);\n  border-radius: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
