// import React, { useState } from 'react';
import "./index.css"
import BodyMode from './components/BodyMode';
import Footer from './components/Footer';
import Navbar from './components/Navbar/Navbar';
const App=()=>
{
  
 
  return(
    <>
    <Navbar/>  
    <BodyMode />
    <Footer/>
   
    
    </>


  )
}

export default App;