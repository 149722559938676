// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* grid c5 */
.grid-c5 .grid-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.grid-c5 .grid-item:last-child {
  margin-bottom: 0;
}
.grid-c5 .grid-item-l {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.grid-c5 .grid-item-l .icon {
  background-color: rgb(254, 30, 0, 0.15);
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grid-c5 .grid-item-l .icon img {
  width: 16px;
}
.grid-c5 .grid-item-l .text {
  color: var(--clr-silver-v1);
}
.grid-c5 .grid-item-l .text span {
  color: #8a8587;
  font-size: 15px;
}
.grid-c5 .grid-item-r span {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/components/Subscriptions/Subscriptions.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,uCAAuC;EACvC,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["/* grid c5 */\n.grid-c5 .grid-item {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n.grid-c5 .grid-item:last-child {\n  margin-bottom: 0;\n}\n.grid-c5 .grid-item-l {\n  display: flex;\n  align-items: center;\n  column-gap: 10px;\n}\n.grid-c5 .grid-item-l .icon {\n  background-color: rgb(254, 30, 0, 0.15);\n  width: 32px;\n  height: 32px;\n  border-radius: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.grid-c5 .grid-item-l .icon img {\n  width: 16px;\n}\n.grid-c5 .grid-item-l .text {\n  color: var(--clr-silver-v1);\n}\n.grid-c5 .grid-item-l .text span {\n  color: #8a8587;\n  font-size: 15px;\n}\n.grid-c5 .grid-item-r span {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
