// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* grid c2 */
.grid-c2 .grid-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}
.grid-c2 .grid-item:last-child {
  margin-bottom: 0;
}
.grid-c2 .grid-item p {
  color: var(--clr-silver-v1);
}
.grid-c2 .grid-item-l .avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}
.grid-c2 .grid-item-l {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.grid-c2 .grid-item-l .text {
  font-weight: 600;
}
.grid-c2 .grid-item-l span {
  display: block;
  font-weight: 400;
  margin-top: 4px;
  font-size: 15px;
  color: #8a8587;
}
.grid-c2 .grid-item-r span {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/components/Transactions/Transactions.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EAIE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AALrB;AAOA;EACE,gBAAgB;AAClB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["/* grid c2 */\n.grid-c2 .grid-item {\n  display: flex;\n}\n.grid-c2 .grid-item {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 28px;\n}\n.grid-c2 .grid-item:last-child {\n  margin-bottom: 0;\n}\n.grid-c2 .grid-item p {\n  color: var(--clr-silver-v1);\n}\n.grid-c2 .grid-item-l .avatar {\n  width: 40px;\n  height: 40px;\n  border-radius: 100%;\n  overflow: hidden;\n}\n.grid-c2 .grid-item-l {\n  display: flex;\n  align-items: center;\n  column-gap: 10px;\n}\n.grid-c2 .grid-item-l .text {\n  font-weight: 600;\n}\n.grid-c2 .grid-item-l span {\n  display: block;\n  font-weight: 400;\n  margin-top: 4px;\n  font-size: 15px;\n  color: #8a8587;\n}\n.grid-c2 .grid-item-r span {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
