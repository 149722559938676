// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
* {    
    margin: 0;

    padding: 0 ;
}

.completeBodyHome
{
    background: #000;
   
}

@media (max-width:720px) {
    .completeBodyHome{
        padding-bottom: 80px;
    }
    
}

.bodycontentHome{
    margin: 0 5.35714285714% 0 5.35714285714% ;

}

.topblack{
    width: 100% ;
    height: 62px;
    
}


/*

bootcampbox
bootcampheading
bootcampdisc
*/

.height20{
    height:20px;
    background:black;
}

.registerLink{
    text-decoration: none;
}







/* Checkout Page*/


/* CSS */


.fit49{
    height:49px;

}



/* mandatory*/

.fmandatory{
    color: #ff0000;
    font-family: IBM Plex Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 105.5%; /* 13.715px */

}

.ferr{
    color: #ff0000;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 105.5%; /* 13.715px */

}

.MPOs{
    cursor:pointer;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;IACI,SAAS;;IAET,WAAW;AACf;;AAEA;;IAEI,gBAAgB;;AAEpB;;AAEA;IACI;QACI,oBAAoB;IACxB;;AAEJ;;AAEA;IACI,0CAA0C;;AAE9C;;AAEA;IACI,YAAY;IACZ,YAAY;;AAEhB;;;AAGA;;;;;CAKC;;AAED;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;;;;;;;AAQA,iBAAiB;;;AAGjB,QAAQ;;;AAGR;IACI,WAAW;;AAEf;;;;AAIA,aAAa;;AAEb;IACI,cAAc;IACd,0BAA0B;IAC1B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAE,aAAa;;AAEtC;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAE,aAAa;;AAEtC;;AAEA;IACI,cAAc;AAClB","sourcesContent":["\n* {    \n    margin: 0;\n\n    padding: 0 ;\n}\n\n.completeBodyHome\n{\n    background: #000;\n   \n}\n\n@media (max-width:720px) {\n    .completeBodyHome{\n        padding-bottom: 80px;\n    }\n    \n}\n\n.bodycontentHome{\n    margin: 0 5.35714285714% 0 5.35714285714% ;\n\n}\n\n.topblack{\n    width: 100% ;\n    height: 62px;\n    \n}\n\n\n/*\n\nbootcampbox\nbootcampheading\nbootcampdisc\n*/\n\n.height20{\n    height:20px;\n    background:black;\n}\n\n.registerLink{\n    text-decoration: none;\n}\n\n\n\n\n\n\n\n/* Checkout Page*/\n\n\n/* CSS */\n\n\n.fit49{\n    height:49px;\n\n}\n\n\n\n/* mandatory*/\n\n.fmandatory{\n    color: #ff0000;\n    font-family: IBM Plex Sans;\n    font-size: 13px;\n    font-style: normal;\n    font-weight: 300;\n    line-height: 105.5%; /* 13.715px */\n\n}\n\n.ferr{\n    color: #ff0000;\n    font-family: IBM Plex Sans;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 300;\n    line-height: 105.5%; /* 13.715px */\n\n}\n\n.MPOs{\n    cursor:pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
