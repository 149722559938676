// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* grid c4 */
.grid-c4 .grid-c-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid-c4-content {
  margin-top: 20px;
  border-radius: 16px;
}
.grid-c4 .grid-c4-content {
  padding: 12px;
}
.grid-c4 .grid-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
}
.grid-c4 .grid-item-l {
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
}
.grid-c4 .grid-item-l .icon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.grid-c4 .grid-item-l .icon img {
  width: 20px;
}
.grid-c4 .grid-item-l .text {
  font-weight: 600;
}
.grid-c4 .grid-item-l .text span {
  font-weight: 300;
  font-size: 15px;
  display: block;
  margin-top: 5px;
  opacity: 0.9;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/components/Budget/Budget.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,eAAe;EACf,YAAY;AACd","sourcesContent":["/* grid c4 */\n.grid-c4 .grid-c-top {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.grid-c4-content {\n  margin-top: 20px;\n  border-radius: 16px;\n}\n.grid-c4 .grid-c4-content {\n  padding: 12px;\n}\n.grid-c4 .grid-item {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  margin-bottom: 24px;\n}\n.grid-c4 .grid-item-l {\n  display: flex;\n  align-items: flex-start;\n  column-gap: 16px;\n}\n.grid-c4 .grid-item-l .icon {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n}\n.grid-c4 .grid-item-l .icon img {\n  width: 20px;\n}\n.grid-c4 .grid-item-l .text {\n  font-weight: 600;\n}\n.grid-c4 .grid-item-l .text span {\n  font-weight: 300;\n  font-size: 15px;\n  display: block;\n  margin-top: 5px;\n  opacity: 0.9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
